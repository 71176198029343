import CryptoJS from "crypto-js";

const readChunked = (file, chunkCallback, endCallback) => {
  let fileSize = file.size;
  let chunkSize = 4 * 1024 * 1024; // 4MB
  let offset = 0;

  let reader = new FileReader();
  reader.onload = function () {
    if (reader.error) {
      endCallback(reader.error || {});
      return;
    }
    offset += reader.result.length;
    // callback for handling read chunk
    // TODO: handle errors
    chunkCallback(reader.result, offset, fileSize);
    if (offset >= fileSize) {
      endCallback(null);
      return;
    }
    readNext();
  };

  reader.onerror = function (err) {
    endCallback(err || {});
  };

  function readNext() {
    let fileSlice = file.slice(offset, offset + chunkSize);
    reader.readAsBinaryString(fileSlice);
  }
  readNext();
};

export const getFileHash = (blob, cbProgress) => {
  return new Promise((resolve, reject) => {
    // let hashAlgo = CryptoJS.algo.MD5.create();
    let hashAlgo = CryptoJS.algo.SHA1.create();
    readChunked(
      blob,
      (chunk, offs, total) => {
        hashAlgo.update(CryptoJS.enc.Latin1.parse(chunk));
        if (cbProgress) {
          cbProgress(offs / total);
        }
      },
      (err) => {
        if (err) {
          reject(err);
        } else {
          // TODO: Handle errors
          let hash = hashAlgo.finalize();
          let hashHex = hash.toString(CryptoJS.enc.Hex);
          resolve(hashHex);
        }
      }
    );
  });
};
