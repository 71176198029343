<template>
  <div class="model-upload-wrapper">
    <!-- @click="handleShowUpload" -->
    <div>
      <el-upload
        ref="modelUpload"
        class="upload-demo"
        :headers="uploadHeaders"
        action=""
        :multiple="false"
        :limit="1"
        :show-file-list="false"
        :file-list="fileList"
        :on-error="handleModelError"
        :before-upload="beforeModelUpload"
        :http-request="customModelUpload"
        :on-exceed="handleExceed"
        :accept="accept"
      >
        <slot>
          <el-button size="small" type="default" :disabled="isUploading"
            >上传模型</el-button
          >
        </slot>
      </el-upload>
    </div>

    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :show-close="true"
      custom-class="info-dialog"
      :before-close="beforeClose"
      width="40%"
    >
      <div class="step-container">
        <el-steps :active="active" finish-status="success">
          <el-step title="上传封面"></el-step>
          <el-step title="上传模型"></el-step>
        </el-steps>
      </div>
      <div class="content-upload">
        <el-upload
          v-if="active === 0"
          class="upload-model-box"
          :class="{ hidden: obsThumbUrl }"
          :headers="uploadHeaders"
          :multiple="false"
          :limit="1"
          action=""
          :on-error="handleImageError"
          :before-upload="beforeImageUpload"
          :http-request="customImageUpload"
          :on-remove="handleRemove"
          :on-exceed="handleExceed"
          :file-list="imgList"
          :disabled="isUploading"
          accept="image/jpeg,image/jpg,image/png,image/bmp"
          list-type="picture-card"
        >
          <!-- <img v-if="obsThumbUrl" :src="obsThumbUrl" class="cover" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
          <i class="el-icon-plus" slot="default"></i>
          <div class="el-upload__tip" slot="tip" style="text-align: center">
            上传模型封面
          </div>
        </el-upload>
        <el-upload
          v-if="active === 1"
          class="upload-demo"
          :headers="uploadHeaders"
          action=""
          multiple
          :show-file-list="false"
          :file-list="fileList"
          :on-error="handleModelError"
          :before-upload="beforeModelUpload"
          :http-request="customModelUpload"
          :accept="accept"
          drag
        >
          <!-- <slot>
            <el-button size="small" type="primary" :disabled="uploadLoading"
              >上传模型</el-button
            >
          </slot> -->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将模型文件拖到此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <div style="text-align: right; padding-top: 20px">
        <el-button
          key="back"
          type="primary"
          @click="gotoNext"
          :disabled="isUploading"
        >
          {{ buttonText }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import { EventBus } from "@/EventBus";
import {getFileHash} from '@/util/fileHash';

export default {
  inject: ["model"],
  props: {
    did: Number,
  },
  data() {
    return {
      SUCCESS_CODE: 0,
      uploadHeaders: {
        token: localStorage.getItem("token"),
      },
      imgList: [],
      fileList: [],
      jobId: "",
      loading: null,
      active: 0,
      buttonText: "下一步",
      obsThumbUrl: "",
      dialogVisible: false,
      accept:
        ".x_b,.xmt,.xmt_txt, .sat,.sab, .stp,.step, .igs,.iges, .CATPart, .sldprt, .ipt,.iam, .asm., .jt, .3dm, .stl, .obj, .par, .psm, .rvt,.rfa, .fbx, .skp, .dwg,.dxf"
    };
  },
  created() {
    this.httpRequest = axios.create({
      timeout: 60000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  computed:{
    isUploading(){
        return this.$store.state.isUploadingModel
    }
  },
  methods: {
    handleShowUpload() {
      this.dialogVisible = true;
    },
    beforeClose(done) {
      this.active = 0;
      this.buttonText = "下一步";
      this.imgList = [];
      this.fileList = [];
      this.obsThumbUrl = "";
      done();
    },
    // 下一步
    gotoNext() {
      if (this.active === 0) {
        this.active++;
        this.buttonText = "上一步";
      } else {
        this.active--;
        this.buttonText = "下一步";
      }
    },
    beforeImageUpload(file) {
      console.log(file);
    },
    handleImageError(err, file, fileList) {
      console.log(err);
    },
    handleRemove(file) {
      this.imgList = [];
      this.obsThumbUrl = "";
    },
    handleExceed(files, fileList) {
      console.log("exceed", fileList);
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeModelUpload(file) {
      console.log(file);
    },
    handleModelError(err, file, fileList) {
      console.log(err);
    },
    // 获取上传凭证/user-job/getUploadAuth
    async getUploadAuth(file, hash) {
      let fileNameNoExt = file.name;
      let fileExt = '';
      let extIndex = file.name.lastIndexOf(".");
      if(extIndex>-1){
        fileNameNoExt = file.name.substring(0, extIndex)
        fileExt = file.name.substring(extIndex+1)
      }
      //文件名不得含特殊字符
      fileNameNoExt = fileNameNoExt.replace(/[\\*&$!@%#\\.。；;，,？\\+]/gi, '_');
      let params = {
        name: fileNameNoExt + '.' + fileExt,
        size: file.size,
        hash: hash,
        directoryId: this.$store.state.uploadModelDirectory,
        obsThumbUrl: this.obsThumbUrl,
        format: fileExt
      };
      this.$http.post("/user-job/getUploadAuth", params)
        .then(res=>{
          if (res.code == this.SUCCESS_CODE) {
            if(res.data.code==1){
              this.ossUpload(res.data.data, file);
            }else if(res.data.code==2){
              res.data.jobUid && this.saveJob(res.data.jobUid)
            }else if(res.data.code==3){
              //todo status=3情况未处理
              this.loading.close();
              if(this.$store.state.currentMenu === 8){
                EventBus.$emit("refreshModelList");
              }else{
                this.model.$refs.sideTree.handleMenuClick(8);
              }
            }
          } else {
            this.loading.close();
            this.$store.commit('closeUploadModel');
            this.$message.error(res.msg);
          }
        }).catch(err=>{
            console.log(err)
            this.loading.close();
            this.$store.commit('closeUploadModel');
        });
    },
    customModelUpload(e) {
      this.$refs.modelUpload.clearFiles();

      let file = e.file;
      this.loading = this.$loading({
        lock: true,
        text: "模型正在加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // deprecated 旧版hash
      // this.getHash(file, this.getUploadAuth)

      // 使用md5的hash值hub上传模型接口有缓存，使用sha1的hash值没有缓存，目前使用sha1避免缓存
      getFileHash(file, 
        progress=>{
          console.log('file hash', progress)
        }
      ).then(
            res => {
                console.log('file hash', res)
                this.getUploadAuth(file, res)
            },
            err => {
                console.error(err)
            }
        );

      //初始化上传目录
      if(!this.$store.state.isUploadingModel && this.model.directoryId){
          this.$store.commit('setUploadModelDirecotry', this.model.directoryId)
      }
      this.$store.commit('startUploadModel');
    },
    getHash(file, cb) {
        let reader = new FileReader();
        // 读取文件 file
        reader.readAsArrayBuffer(file);

        reader.onload = function () {
            console.log("----buffer----" + reader.result);
            var wordArray = CryptoJS.lib.WordArray.create(reader.result);
            var hash = CryptoJS.SHA1(wordArray).toString(); // 计算其他加密算法，SHA1改为MD5、SHA256即可
            console.log("hash:", hash);
            cb && cb(file, hash);
        };

        reader.onerror = function(err){
          console.log(err)
          this.loading.close();
          this.dialogVisible = false;
          this.$message.error('模型上传失败');
          this.$store.commit('closeUploadModel');
        }
    },
    ossUpload(params, file) {
      //   console.log(params);
      var formdata = new FormData();
      //通过append()方法在末尾追加参数
      formdata.append("key", params.key);
      formdata.append("policy", params.policy);
      formdata.append("OSSAccessKeyId", params.ossaccessKeyId);
      formdata.append("success_action_status", params.success_action_status);
      formdata.append("callback", params.callback);
      formdata.append("signature", params.signature);
      formdata.append("file", file);
      formdata.append("name", file.name);
      this.httpRequest
        .post(params.host, formdata)
        .then((res) => {
          this.fileList = [];
          //   console.log(res);
          if (res.data.code == 200000) {
            //   保存jobid
            let jobId = res.data.info.data.job_uid;
            this.saveJob(jobId, params.resourceId, params.dataId);
          } else if(res.data.message === 'OK') {
            //   保存jobid
            let jobId = res.data.data.job_uid;
            this.saveJob(jobId, params.resourceId, params.dataId);
          } else {
            this.loading.close();
            this.$message.error(res.data.info.data.message);
          }
          this.$store.commit('closeUploadModel');
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.fileList = [];
          this.$store.commit('closeUploadModel');
          this.dialogVisible = false;
          this.loading.close();
          this.$message.error("上传失败" + err);
        });
    },
    // 保存工作id
    saveJob(jobId, resourceId, id) {
      this.$http
        .post("/user-job/saveJob", { id, jobId, resourceId})
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            // this.model.getData(true);
            this.$message.success(res.msg);
            // 上传成功后，跳转到模型列表页面；
            // this.model.getMenuIndex(8);
            if(this.$store.state.currentMenu === 8){
              EventBus.$emit("refreshModelList");
            }else{
              this.model.$refs.sideTree.handleMenuClick(8);
            }
            // EventBus.$emit("toggleToolBar", 8);
            // EventBus.$emit("setCurrent", 8);
          } else {
            this.$message.error(res.msg);
          }
          this.$store.commit('closeUploadModel');
          this.loading.close();
        })
        .catch((err) => {
          this.$store.commit('closeUploadModel');
          this.loading.close();
          this.$message.error("上传失败" + err);
        });
    },
    customImageUpload(e) {
      let self = this;
      var file = e.file;
      var key = uuidv4() + file.name;
      self.obsUpload(e.file, key, null);
    },
    // obs上传图片
    obsUpload(file, key, callback) {
      let self = this;
      var ak = self.$OBS_AK;
      var sk = self.$OBS_SK;
      var server = self.$OBS_SERVER;
      var bucket = self.$OBS_BUCKET;
      let prefix = self.$OBS_PREFIX;
      var obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
      });
      // put objects
      obsClient
        .putObject({
          Bucket: bucket,
          Key: key,
          Metadata: { property: "property-value" },
          SourceFile: file,
        })
        .then(function (result) {
          if (result.CommonMsg.Status == 200) {
            // 上传成功后，关闭上传界面，打开修改名称弹框
            callback && callback(file, key);
            let obsUrl = prefix + key;
            let thumbSrc =
              obsUrl + "?x-image-process=image/resize,w_210,limit_0";
            console.log(thumbSrc);
            self.obsThumbUrl = thumbSrc;
          }
          self.$store.commit('closeUploadModel');
        })
        .catch(function (err) {
          self.$store.commit('closeUploadModel');
          console.log(err);
        });
    },
    getFileFormat(file) {
      var fileName = file.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
      var fileNameLength = file.name.length; //取到文件名长度
      var fileFormat = file.name.substring(fileName + 1, fileNameLength); //截
      return fileFormat;
    },
  },
};
</script>

<style scoped lang="less">
.info-dialog {
  .step-container {
    width: 80%;
    padding: 20px 50px;
    margin: 0 auto;
  }
}
.content-upload {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 200px;
  .cover {
    width: 100%;
    height: 100%;
  }
  .upload-model-box {
    &.hidden {
      /deep/ .el-upload {
        display: none;
      }
    }
  }
}
</style>
